@font-face {
  font-family: App;
  src: $ol-font-family-regular-src;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: App;
  src: $ol-font-family-bold-src;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: App;
  src: $ol-font-family-bold-src;
  font-weight: 500;
  font-display: swap;
}

// Secondary font (for headlines)

@font-face {
  font-family: AppHeadlines;
  src: $ol-secondary-font-family-italic-src;
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: AppHeadlines;
  src: $ol-secondary-font-family-regular-src;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: AppHeadlines;
  src: $ol-secondary-font-family-bold-src;
  font-weight: bold;
  font-display: swap;
}
