// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
/*html {
  box-sizing: border-box;
}*/

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
/*
*, *::before, *::after {
  box-sizing: inherit;
}
*/

/**
 * Basic styles for links
 */
/*
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}
*/
* {
  padding: 0;
  margin: 0;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
}

body {
  font-family: $ol-font-family-primary;
  overflow: hidden;
  min-height: 100vh; /* fall-back */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: stretch;
}

#root {
  height: 100vh;
}

img {
  vertical-align: baseline;
}

input {
  font-weight: normal;

  &::-webkit-credentials-auto-fill-button,
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

.standalone * {
  -webkit-touch-callout: none;
}

h1 {
  margin: 0 !important;
}

h2 {
  margin: 0 !important;
}

h3 {
  margin: 0 !important;
}

h4 {
  margin: 0 !important;
}

h1,
h2,
h3 {
  font-family: $ol-font-family-titles !important;
}
