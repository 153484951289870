/*SCROLL*/

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 24px;
  width: 8px;
  border: 2px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: $ol-cloudyGrayColor;
  -webkit-box-shadow: none;
}

.ol-scroll {
  &-light {
    &::-webkit-scrollbar-thumb {
      background-color: $ol-cloudyGrayColor;
    }
  }

  &-dark {
    &::-webkit-scrollbar-thumb {
      background-color: $ol-silverColor;
    }
  }

  &.scrollY {
    overflow-y: scroll;
  }
}
