.ant-menu-inline,
.ant-menu-vertical {
  border-right: 0;
}

#organizationsTable {
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: $ol-primaryColorLight;
  }
}

#sitesTable {
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #fafafa;
  }

  .ant-table-thead > tr > th {
    background: $ol-primaryColorLight;
  }
}

#departmentsTable {
  .ant-table-thead > tr > th {
    background: #fafafa;
  }
}

.ant-table-filter-dropdown-tree {
  font-weight: 400;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 180px;
}

//ant tab
.ant-tabs-tab {
  color: $ol-secondaryColor-3 !important;
  &:hover {
    color: $ol-primaryColor-6 !important;
    background-color: $ol-secondaryColor-7 !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $ol-primaryColor-4 !important;
  background-color: #f4f4f1 !important;
  &:hover {
    color: $ol-primaryColor-6 !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #f4f4f1;
}

.ant-tabs-ink-bar {
  background: $ol-primaryColor-4 !important;
}

//ant tag
.ant-tag-checkable {
  &:hover {
    color: $ol-primaryColor-6 !important;
  }
  &:active {
    background-color: transparent !important;
  }
}

.ant-tag-checkable-checked {
  background-color: #f4f4f1 !important;
  color: $ol-primaryColor-4 !important;
  border-bottom: $ol-primaryColor-4 solid 2px !important;
}
