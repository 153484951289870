// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// COLORS
$ol-whiteColor: #fff !default;
$ol-blackColor: #000000 !default;

$ol-silverColor: #8f8f8f !default;
$ol-cloudyGrayColor: #c9c9c9 !default;
$ol-lightGrayColor: #eeeeee !default;
$ol-grayBackgroundColor: #f3f3f3 !default;

$ol-primaryColor: #f04a1d !default;

// OLD - Deprecated, use "Primary colors" section instead
$ol-primaryColor-110: #f04819 !default;
$ol-primaryColor-90: #f3663f !default;
$ol-primaryColor-80: #f47552 !default;
$ol-primaryColor-70: #f58566 !default;
$ol-primaryColor-60: #f69479 !default;
$ol-primaryColor-50: #f8a38c !default;
$ol-primaryColor-40: #f9b39f !default;
$ol-primaryColor-30: #fac2b2 !default;
$ol-primaryColor-20: #fbd1c5 !default;
$ol-primaryColor-10: #fde0d9 !default;
$ol-primaryColorLight: #fff2eb !default;

// Primary colors
// darker variations
$ol-primaryColor-1: #902c11;
$ol-primaryColor-2: #a83414;
$ol-primaryColor-3: #c03b17;
$ol-primaryColor-4: #d8431a;
// lighter variations
$ol-primaryColor-5: #f58061;
$ol-primaryColor-6: #f69277;
$ol-primaryColor-7: #f9b7a5;

// OLD - Deprecated, use "Secondary colors" section instead
$ol-secondaryColor: #e6e5e3 !default;

// Secondary colors
$ol-secondaryColor-1: #000000;
$ol-secondaryColor-2: #181819;
$ol-secondaryColor-3: #323435;
$ol-secondaryColor-4: #464748;
$ol-secondaryColor-5: #808080;
$ol-secondaryColor-6: #dcdcdd;
$ol-secondaryColor-7: #f5f5f4;
$ol-secondaryColor-8: #ffffff;

$ol-auxiliaryColor: #c2ff7d !default;

$ol-successColor: #90fa3a !default;
$ol-infoColor: #eeeb4a !default;
$ol-errorColor: #fc4a3e !default;

$ol-statusGreenColor: #52c41a !default;
$ol-statusRedColor: #f5222d !default;
$ol-statusYellowColor: #d4b106 !default;
$ol-statusGrayColor: #8c8c8c !default;

// OLD - Deprecated, charts should use "Complementary colors" section instead
$ol-chartColor-0: #8bd3c7;
$ol-chartColor-1: #bd7ebe;
$ol-chartColor-2: #7eb0d5;
$ol-chartColor-3: #b2e061;
$ol-chartColor-4: #fd7f6f;
$ol-chartColor-5: #ffb55a;
$ol-chartColor-6: #ffee65;
$ol-chartColor-7: #beb9db;
$ol-chartColor-8: #fdcce5;
$ol-chartColor-9: #b3b2b2;

// Complementary colors
$ol-darkGreen: #38584b;
$ol-blue: #0076a8;
$ol-green: #b7db57;
$ol-yellow: #ffce54;

$ol-chart-darkGreen: #38584bb2;
$ol-chart-blue: #0076a8b2;
$ol-chart-green: #b7db57b2;
$ol-chart-yellow: #ffce54b2;
$ol-chart-orange: #f58061b2;

// FONTS
$ol-font-family-primary: App, "Helvetica", "Arial", "sans-serif" !default;
$ol-font-family-titles: AppHeadlines, App, "Helvetica", "Arial", "sans-serif" !default;

$ol-font-family-regular-src: url("~assets/stylesheets/fonts/current/Sohne-Buch.ttf") format("truetype") !default;
$ol-font-family-bold-src: url("~assets/stylesheets/fonts/current/Sohne-Buch-Bold.otf") format("opentype") !default;

$ol-secondary-font-family-italic-src: url("~assets/stylesheets/fonts/current/ChronicleTextG1-Italic-Pro_Web.woff") format("woff") !default;
$ol-secondary-font-family-regular-src: url("~assets/stylesheets/fonts/current/ChronicleTextG1-Semibd-Pro.otf") format("opentype") !default;
$ol-secondary-font-family-bold-src: url("~assets/stylesheets/fonts/current/ChronicleTextG1-Bold-Pro_Web.woff") format("woff") !default;

// BREAKPOINTS
$breakpoints: (
  "xs": (
    max-width: 575px,
  ),
  "sm": (
    max-width: 768px,
  ),
  "md": (
    max-width: 992px,
  ),
  "lg": (
    max-width: 1200px,
  ),
  "xl": (
    max-width: 1600px,
  ),
  "xxl": (
    min-width: 1601px,
  ),
) !default;

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass functions.
// -----------------------------------------------------------------------------

/// Native `url(..)` function wrapper
/// @param {String} $base - base URL for the asset
/// @param {String} $type - asset type folder (e.g. `fonts/`)
/// @param {String} $path - asset path
/// @return {Url}
@function asset($base, $type, $path) {
  @return url($base + $type + $path);
}

/// Returns URL to an image based on its path
/// @param {String} $path - image path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function image($path, $base: $base-url) {
  @return asset($base, "images/", $path);
}

/// Returns URL to a font based on its path
/// @param {String} $path - font path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function font($path, $base: $base-url) {
  @return asset($base, "fonts/", $path);
}

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/* MIXINS */

@mixin image-2x($image, $width, $height: $width) {
  @media (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    @if not $width {
      /* stylelint-disable-line */
    } @else {
      @if $width == $height {
        background-size: $height;
      } @else {
        background-size: $width $height;
      }
    }
    background-repeat: no-repeat;
  }
}

@mixin imageBackground-2x($image) {
  @media (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@mixin buttonHoverEffect($bg, $color) {
  /*
  background: $bg;
  */
  &:hover {
    background: darken($bg, 5%);
    transition: all 0.3s ease;
    color: $color;
  }
}

@mixin hoverEffect() {
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }
}

@mixin border($width, $color) {
  border: {
    width: $width;
    style: solid;
    color: $color;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radiusBottom($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-radiusTop($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin flex($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin grid() {
  display: grid;
  > * {
    min-height: 0;
    min-width: 0;
  }
}

/// Responsive breakpoint manager
/// @access publicHome
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == "string", unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Bootstrap v4 needed mixins
@mixin hover-focus {
  &:focus,
  &:hover {
    @content;
  }
}

@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (1rem / 2) 0;
  overflow: hidden;
  background-color: $color;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin form-control-focus($color: #66afe9) {
  $color-rgba: rgba(red($color), green($color), blue($color), 0.6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $color-rgba);
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin placeholder-color($color: #66afe9) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  // Safari and Chrome
  &::placeholder {
    color: $color;
  }
}

@mixin animated-background($width, $height, $animation-duration) {
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: $width $height;
  height: $height;
  position: relative;
}

// animated-background mixin helper
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

// http://aslanbakan.com/en/blog/browser-and-device-specific-css-styles-with-sass-and-less-mixins
@mixin browser($browsers) {
  @each $browser in $browsers {
    html[data-browser*="#{$browser}"] & {
      @content;
    }
  }
}

@mixin ellipsisMultiline($linesCount: 1) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: $linesCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @supports not (-webkit-line-clamp: $linesCount) {
    height: 1em * 1.2 * $linesCount; /* Fallback for non-webkit */
    line-height: 1.2;
  }

  @include browser(Trident) {
    white-space: nowrap;
  }
}

@mixin ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin image-background-cover() {
  object-fit: cover;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  }
}

//HIDE ON MOBILE
@mixin hide-on-mobile() {
  @include respond-to("xs") {
    display: none;
  }
}

@mixin v-spacing($spacing) {
  > :not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin h-spacing($spacing) {
  > :not(:last-child) {
    margin-right: $spacing;
  }
}

// https://raoulkramer.de/avif-and-webp-images-as-css-background-images
// https://github.com/djpogo/avif-webp-css-support
@mixin background-image($url, $webp1x: false, $avif1x: false, $url2x: false, $webp2x: false, $avif2x: false) {
  background-image: $url;
  @if $webp1x {
    .webp & {
      background-image: $webp1x;
    }
  }
  @if $avif1x {
    .avif & {
      background-image: $avif1x;
    }
  }
  @if $url2x {
    @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
      background-image: $url2x;
      @if $webp2x {
        .webp & {
          background-image: $webp2x;
        }
      }
      @if $avif2x {
        .avif & {
          background-image: $avif2x;
        }
      }
    }
  }
}

@mixin subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 26px;
  background-color: $ol-secondaryColor;
  height: 48px;
}

@mixin filteredIcon {
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  color: #fff;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapseContent {
  width: 100%;
}

.panelHeaderText {
  margin: 0 !important;
}

.numberDisplay {
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 25%;
  justify-content: center;
  align-items: center;
  background: #e6e5e3;
}

.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.searchActive {
  @include filteredIcon;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  @include respond-to("md") {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.endDatePicker {
  width: 182px !important;
}

.downloadButton {
  width: max-content;
  align-self: flex-end;
  margin: 10px 0;

  @include respond-to("md") {
    align-self: flex-start;
  }
}

.cell {
  display: flex;
  justify-content: right;
  height: 100%;
  width: 100%;
}

.selectedDay {
  background-color: $ol-primaryColor-40;
}
