.amplify-heading {
  text-align: center;
}

.amplify-authenticator__subtitle {
  font-size: 16px;
}

:root,
[data-amplify-theme] {
  --amplify-colors-brand-primary-10: #{$ol-primaryColor-10};
  --amplify-colors-brand-primary-100: #{$ol-primaryColor};
  --amplify-colors-brand-primary-20: #{$ol-primaryColor-20};
  --amplify-colors-brand-primary-40: #{$ol-primaryColor-40};
  --amplify-colors-brand-primary-60: #{$ol-primaryColor-60};
  --amplify-colors-brand-primary-80: #{$ol-primaryColor-80};
  --amplify-colors-brand-primary-90: #{$ol-primaryColor-90};
  --amplify-components-button-primary-background-color: #{$ol-primaryColor};
  --amplify-components-button-primary-hover-background-color: #{$ol-primaryColor};
  --amplify-components-button-link-color: #{$ol-primaryColor};
  --amplify-components-button-link-focus-color: #{$ol-primaryColor};
  --amplify-components-button-link-hover-color: #{$ol-primaryColor};
  --amplify-components-button-link-hover-background-color: #{$ol-primaryColor-7};
  --amplify-components-button-link-active-color: #{$ol-primaryColor};
  --amplify-components-tabs-item-active-color: #{$ol-primaryColor};
  --amplify-components-tabs-item-active-border-color: #{$ol-primaryColor};
  --amplify-components-tabs-item-active-hover-color: #{$ol-primaryColor};
  --amplify-components-tabs-item-hover-color: #{$ol-primaryColor};
}
